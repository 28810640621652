.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  backdrop-filter: blur(3px);
  z-index: 999;
}

.bloodSplatter {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-image: url("path_to_blood_splatter_image.png"); */
  background-size: cover;
  filter: blur(4px);
  opacity: 0;
  animation: splatterAnimation 2s forwards;
}

@keyframes splatterAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.textContainer {
  z-index: 1;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textContainer div {
  color: rgb(195, 29, 29);
  font-family: Modak;
  margin-bottom: 20px;
  font-size: 6rem;
}

.textContainer button {
  font-size: 1.5625rem;
  cursor: pointer;
  background-color: #62a0e8;
  border: none;
  color: white;
}
