.container {
  position: relative;
  z-index: 2;
  text-align: center;
}

.heading {
  font-family: Koulen;
  font-size: 6rem;
  font-weight: 400;
  text-decoration: underline;
  line-height: 10.84rem;
  text-align: center;
  color: #272626;
}

.charactersContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  padding-left: 0.375rem;
  padding-right: 1.5625rem;
  position: relative;
  gap: 5rem;
}

.charGrid {
  display: flex;
  grid-gap: 4.25rem 2.875rem;
  flex-wrap: wrap;
  max-width: 60rem;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  margin-top: 5vh;
  flex-basis: 65%;
  flex-grow: 0;
  flex-shrink: 0;
}

.charDisplay {
  margin-top: 0rem;
  transform: rotateZ(-7.47deg);
  display: flex;
  justify-content: flex-end;
  flex-basis: 35%;
  flex-grow: 0;
  flex-shrink: 0;
}

.charDisplay img {
  max-height: 45rem;
  height: 100%;
  object-fit: contain;
}

.characterWrapper {
  width: max-content;
  background-color: rgba(76, 81, 90, 0.85);
  padding: 1.375rem 1rem 0.5625rem 1rem;
  border-radius: 0.875rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: transparent 1px solid;
  transition: all 0.3s ease;
}

.characterWrapper:active {
  transform: scale(0.9);
}

.characterWrapper:hover {
  border: #00ffe0 1px solid;
}

.characterWrapper.selected {
  border: #00ffe0 1px solid;
}

.imgWrapper {
  background-color: #2f3238;
  border-radius: 0.8125rem;
  margin-bottom: 2.5625rem;
}

.imgWrapper img {
  width: 7.875rem;
  height: 7.3125rem;
  object-fit: cover;
}

.hr {
  width: 100%;
  border-top: #6a7486 3px solid;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
}

.name {
  font-size: 2.25rem;
  font-family: Monomaniac One;
  font-weight: 400;
  line-height: 3.2581rem;
  color: #ffffff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
}
