.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  backdrop-filter: blur(3px);
  z-index: 9999;
  position: absolute;
  top: 0;
  height: 100vh;
}

.contentWrapper {
  background-color: #1dc9febf;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.25rem 1.5rem;
}

.header {
  font-family: Modak;
  font-size: 2.5rem;
  font-weight: 400;
  color: #fff;
  line-height: 3.7644rem;
}

.canvas {
  position: relative;
  height: 24.6875rem;
  width: 27.875rem;
  overflow: hidden;
  border: #000 solid 1px;
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.canvasLeft {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  top: 20px;
  left: 10px;
  z-index: 5;
}

.canvasLeft img {
  height: 10rem;
  width: auto;
  margin-top: -0.625rem;
}

.canvas span {
  font-family: Nerko One;
  color: #fff;
}

.stroke {
  text-shadow: -0.0625rem -0.0625rem 0 #000, 0.0625rem -0.0625rem 0 #000,
    -0.0625rem 0.0625rem 0 #000, 0.0625rem 0.0625rem 0 #000;
}

.beltSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}

.charImg {
  position: absolute;
  left: 57%;
  transform: rotate(-7.47deg);
  height: 22.1875rem;
  width: auto;
  z-index: 2;
}

.contentWrapper button {
  box-shadow: rgba(0, 0, 0, 0.6) 0px 4px 4px 2px;
  height: 3.625rem;
  border: #000 solid 1px;
  font-size: 2rem;
}

.shareBtn {
  padding-top: 0.35rem !important;
  background-color: #62a0e8 !important;
  margin-bottom: 0.8125rem;
  font-family: Modak !important;
  color: #fff !important;
  font-size: 2rem !important;
  border: #000 solid 1px !important;
}

.pumpBtn {
  background-color: #21b71e;
  margin-bottom: 0.8125rem;
}

.pumpBtn img {
  width: 2.9375rem;
  height: auto;
}

.replay {
  height: 3.625rem;
}

.winText {
  font-family: "Modak";
  font-size: 2.5rem;
  color: white;
  text-align: center;
}
