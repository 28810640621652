@import url("https://fonts.googleapis.com/css2?family=Modak&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Koulen&family=Modak&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Koulen&family=Miriam+Libre:wght@400;700&family=Modak&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Koulen&family=Miriam+Libre:wght@400;700&family=Modak&family=Nerko+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nerko+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta+Mahee:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ruda:wght@400..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 0.8vw;
}

body {
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.App {
  height: 100%;
}

.bg {
  min-height: 100vh;
  position: fixed;
  inset: 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.blur {
  backdrop-filter: blur(3px);
}

.header {
  color: #00ffe0;
  font-family: "Ruda", sans-serif;
  text-align: center;
  font-weight: 900;
  font-size: clamp(1rem, 5vw, 4rem);
  text-shadow: -0.0625rem -0.0625rem 0 #000, 0.0625rem -0.0625rem 0 #000,
    -0.0625rem 0.0625rem 0 #000, 0.0625rem 0.0625rem 0 #000,
    0.5rem 0.5rem 0.2437rem rgba(0, 0, 0, 0.25);
}

.content {
  position: relative;
  z-index: 1;
  min-height: 100vh;
}

.button {
  background-color: #08171e;
  border-radius: 8px;
  color: #fff;
  height: 2.875rem;
  width: 11.75rem;
  font-size: 1.5rem;
  font-weight: 900;
  font-family: "Ruda", sans-serif;
  cursor: pointer;
  border: none;
  outline: none;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  display: block;

  &:active {
    transform: scale(0.9);
  }
}

.link {
  cursor: pointer;
  img {
    width: 3.125rem;
  }
}

.links {
  display: flex;
  gap: 2.25rem;
  justify-content: center;
  align-items: center;
  margin-top: 1.875rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fightingScreen {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.fightingScreenHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 3vw;
}

.scoreBoard {
  min-height: 5.875rem;
  padding: 0.5rem 0;
}

.leftHeader {
  color: #ffffff;
  font-family: "Modak", cursive;
  text-align: center;
  font-size: clamp(1rem, 5vw, 4rem);
  text-shadow: -0.0625rem -0.0625rem 0 #000, 0.0625rem -0.0625rem 0 #000,
    -0.0625rem 0.0625rem 0 #000, 0.0625rem 0.0625rem 0 #000,
    0.5rem 0.5rem 0.2437rem rgba(0, 0, 0, 0.25);
}

.rightHeader {
  color: #fff;
  font-family: "Modak", cursive;
  text-align: center;
  font-size: clamp(0.625rem, 5vw, 2.5rem);
  text-shadow: -0.0625rem -0.0625rem 0 #000, 0.0625rem -0.0625rem 0 #000,
    -0.0625rem 0.0625rem 0 #000, 0.0625rem 0.0625rem 0 #000,
    0.5rem 0.5rem 0.2437rem rgba(0, 0, 0, 0.25);
}

.scoreBoardContainer {
  max-width: 48.6875rem;
  border-radius: 1.3125rem;
  box-shadow: 0 0.6875rem 0.25rem 0.125rem rgba(0, 0, 0, 0.7);
  width: 100%;
  margin: 0 1rem;
  min-height: 5.875rem;
  background-color: rgba(255, 255, 255, 0.8);
}

.scoreBoardContainer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.scoreBoardTitle {
  font-size: 1.5rem;
  font-family: "Miriam Libre", sans-serif;
}

.bold {
  font-family: "Miriam Libre", sans-serif;
  font-weight: 700;
  font-size: 3rem;
}

.round,
.roundResult {
  flex-basis: 26%;
  padding-top: 0.5rem;
  text-align: center;
}

.round {
  border-right: 0.125rem solid #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.roundResult {
  border-left: 0.125rem solid #000;
}

.scoreBoard {
  padding-top: 0.75rem;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 1rem;
}

.winnerLog {
  position: relative;

  img {
    position: absolute;
    width: 4.25rem;
    height: 4.25rem;
    aspect-ratio: 1;
    object-fit: contain;

    &:nth-child(1) {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 0;
      left: 33%;
    }

    &:nth-child(3) {
      top: 0;
      right: 0;
    }
  }
}

.actionButton {
  height: 3rem;
  min-width: 10.6875rem;
  font-size: 2rem;
  font-family: "Ruda", cursive;
  border-radius: 0.8125rem;
  border: 1px solid #000;
  display: flex;
  font-weight: 600;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:first-child {
    font-size: 1.75rem;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.9);
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.125rem;
  text-align: center;
  padding-right: 3vw;
  padding-top: 2vh;
}

.punch {
  background-color: #fe4a49;
}

.quick {
  background-color: #f4d6cc;
}

.defend {
  background-color: #32e875;
}

.leftFighter {
  position: absolute;
  left: 7vw;
  bottom: 3vh;
  pointer-events: none;

  img.leftFighterImg {
    transform: scaleX(-1);
    object-fit: contain;
    width: 21vw;
    height: 48vh;
  }
}

.rightFighter {
  position: absolute;
  right: 7vw;
  bottom: 16vh;
  pointer-events: none;

  img.rightFighterImg {
    object-fit: contain;
    width: 23vw;
    height: 52vh;
  }
}

.info {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  color: #fff;
  transform: translateX(-50%);
  font-family: "Miriam Libre", sans-serif;
  font-weight: 700;
  text-align: center;
}

.fighterAction {
  position: absolute;
  bottom: 0;

  img {
    width: 12.5rem;
    aspect-ratio: 1;
  }
}

.smMove {
  position: absolute;
  top: 22%;

  img {
    max-width: 12.5rem;
    aspect-ratio: 1;
  }
}

.fightingScreenHeader .links {
  margin-top: 0;

  img {
    width: 2.6rem;
  }
}

.canvasContainer {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.belt {
  max-width: 29.75rem;
  margin: auto;
  width: 100%;
}

.beltContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -2rem;
}

.startScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mobileWarning {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-self: center;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  max-width: 80%;
  color: #000;
  margin: auto;
}

.infoHeader {
  color: #fff;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}
